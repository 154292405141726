$newsletter-title-color                      : color(base) !default;
$newsletter-title-font-family                : $font-headline !default;
$newsletter-title-font-size--mobile          : font-size(xxl-m) !default;
$newsletter-title-font-size--desktop         : font-size(xxl) !default;
$newsletter-title-margin-bottom--mobile      : spacing(s) !default;
$newsletter-title-margin-bottom--desktop     : spacing(s) !default;
$newsletter-text-color                       : color(base) !default;
$newsletter-text-font-family                 : $font-base !default;
$newsletter-text-font-size                   : font-size(m) !default;
$newsletter-text-font-margin-bottom--mobile  : spacing(m) !default;
$newsletter-text-font-margin-bottom--desktop : spacing(l) !default;

.c-newsletter {
    display        : flex;
    flex-direction : column;
    align-items    : center;

    .c-newsletter__title {
        color         : $newsletter-title-color;
        font-family   : $newsletter-title-font-family;
        font-size     : $newsletter-title-font-size--mobile;
        margin-bottom : $newsletter-title-margin-bottom--mobile;

        @include mq($from : desktop) {
            font-size     : $newsletter-title-font-size--desktop;
            margin-bottom : $newsletter-title-margin-bottom--desktop;
        }
    }

    .c-newsletter__text {
        color         : $newsletter-text-color;
        font-family   : $newsletter-text-font-family;
        font-size     : $newsletter-text-font-size;
        margin-bottom : $newsletter-text-font-margin-bottom--mobile;
        text-align    : center;

        @include mq($from : desktop) {
            margin-bottom : $newsletter-text-font-margin-bottom--desktop;
        }
    }

    .c-newsletter__link {
        text-decoration : none;
        width           : 100%;
        display         : flex;
        justify-content : center;
    }
}
